<template>
  <div class="info-fault-wrapper">
    <ListItem title="维修内容：" :borderBottom="false">
      <i slot="icon">*</i>
    </ListItem>
    <div class="fault-reason-box">
      <van-field
        v-model="repairContent"
        rows="2"
        autosize
        type="textarea"
        maxlength="100"
        placeholder="请输入内容"
        show-word-limit
      />
    </div>
    <div class="upload-img-box">
      <div class="title">上传照片：（0/3）</div>
      <div class="upload-content">
        <van-uploader
          v-model="repairFileList"
          :after-read="uploadImg"
          multiple
        />
      </div>
    </div>
    <ListItem title="维修开始时间：">
      <i slot="icon">*</i>
      <div class="select-box" slot="value" @click="handleSelectStartTime">
        <span v-if="startTime" style="margin-right: 0.6rem">
          {{ startTime }}
        </span>
        <span v-if="!startTime"> 请选择 </span>
        <i
          class="iconfont icontiaozhuanqianwangyoujiantouxiangyouxiayibuxianxing"
          v-if="!startTime"
        ></i>
      </div>
    </ListItem>
    <ListItem title="维修结束时间：">
      <i slot="icon">*</i>
      <div class="select-box" slot="value" @click="handleSelectEndTime">
        <span v-if="endTime" style="margin-right: 0.6rem">
          {{ endTime }}
        </span>
        <span v-if="!endTime"> 请选择 </span>
        <i
          class="iconfont icontiaozhuanqianwangyoujiantouxiangyouxiayibuxianxing"
          v-if="!endTime"
        ></i>
      </div>
    </ListItem>
    <ListItem
      :title="`维修人员：(${repairUserIds.length}人)`"
      :borderBottom="true"
      id="trigger"
    >
      <i slot="icon">*</i>
      <div class="select-box" slot="value">
        <span> 请选择 </span>
        <i
          class="iconfont icontiaozhuanqianwangyoujiantouxiangyouxiayibuxianxing"
        ></i>
      </div>
    </ListItem>
    <div class="upload-img-box">
      <van-tag
        closeable
        plain
        size="medium"
        type="primary"
        v-for="(item, index) of repairUserIds"
        :key="item.name"
        @close="deleteRepairUser(index)"
      >
        {{ item.name }}
      </van-tag>
    </div>
    <ListItem
      :title="`验收人员：(${approvalUserIds.length}人)`"
      :borderBottom="false"
      id="triggerAcceptance"
    >
      <i slot="icon">*</i>
      <div class="select-box" slot="value">
        <span> 请选择 </span>
        <i
          class="iconfont icontiaozhuanqianwangyoujiantouxiangyouxiayibuxianxing"
        ></i>
      </div>
    </ListItem>
    <div class="upload-img-box">
      <van-tag
        closeable
        plain
        size="medium"
        type="primary"
        v-for="(item, index) of approvalUserIds"
        :key="item.name"
        @close="deleteAcceptanceUser(index)"
      >
        {{ item.name }}
      </van-tag>
    </div>

    <!--选择时间-->
    <div class="van-picker-box">
      <van-popup v-model="showPicker" position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          type="datetime"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="onConfirm"
        />
      </van-popup>
    </div>
    <div class="van-qrcode-box">
      <van-popup v-model="show">
        <div class="qrcode-img-box">
          <i class="iconfont iconguanbi" @click="handleCloseClick"></i>
          <div class="img-box"></div>
          <p>其他维修人员扫一扫加入维修工单</p>
        </div>
      </van-popup>
    </div>
    <!--添加维修人员-->
    <!-- <div class="van-repair-box">
      <van-dialog
        v-model="showRepair"
        title="添加维修人员"
        show-cancel-button
        confirmButtonText="添加"
        @confirm="confirmRepairAdd"
        @cancel="cancelRepairAdd"
      >
        <div class="search-wrapper">
          <van-field
            v-model="repairPeople"
            placeholder=" 请输入工号"
            @input="getEmployeeUser"
          />
          <div class="search-result-wrapper" v-if="searchList.length">
            <ul>
              <li
                v-for="item of searchList"
                :key="item.id"
                @click="handleSelectRepairList(item)"
              >
                {{ item.realname }}
              </li>
            </ul>
          </div>
        </div>
      </van-dialog>
    </div> -->
    <!--添加验收人员-->
    <!-- <div class="van-repair-box">
      <van-dialog
        v-model="showAcceptance"
        title="添加验收人员"
        show-cancel-button
        confirmButtonText="添加"
        @confirm="confirmAcceptanceAdd"
        @cancel="cancelAcceptanceAdd"
      >
        <div class="search-wrapper">
          <van-field
            v-model="acceptancePeople"
            placeholder=" 请输入工号"
            @input="getEmployeeAcceptanceUser"
          />
          <div class="search-result-wrapper" v-if="searchListAcceptance.length">
            <ul>
              <li
                v-for="item of searchListAcceptance"
                :key="item.id"
                @click="handleSelectAcceptanceList(item)"
              >
                {{ item.realname }}
              </li>
            </ul>
          </div>
        </div>
      </van-dialog>
    </div> -->
  </div>
</template>

<script>
import Vue from 'vue'
import ListItem from '@/components/ListItem'
import moment from 'moment'
import {
  Field,
  Uploader,
  DatetimePicker,
  Popup,
  Picker,
  Dialog,
  Toast,
  Tag,
} from 'vant'
import { getEmployeeUser, loadDeptWithUsersTrees } from '@/api/common'

Vue.use(Popup)
Vue.use(Uploader)
Vue.use(Field)
Vue.use(DatetimePicker)
Vue.use(Picker)
Vue.use(Dialog)
Vue.use(Tag)
Vue.use(Toast)
import { getQiNiuToken } from '@/api/common'
import { imgUrl, QiNiuUrl } from '@/config/env'
import axios from 'axios'
import { mapGetters } from 'vuex'
import PickerExtend from 'picker-extend'
export default {
  name: 'InfoRepair',
  components: {
    ListItem,
  },
  computed: {
    ...mapGetters(['user']),
  },
  props: {
    detailInfo: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  watch: {
    detailInfo: {
      handler(val) {
       
      },

      deep: true,
      immediate: true,
    },
  },
  created() {
    this.$nextTick(() => {
      this.get_loadDeptWithUsersTrees()
    })
  },
  mounted() {
    this.getQiNiuToken()
    this.userInfo = this.user.user
    if (this.userInfo) {
      this.repairUserIds.push({
        name: this.userInfo.nickName,
        id: this.userInfo.id,
      })
    }
  },
  data() {
    return {
      userInfo: null,
      show: false,
      showRepair: false,
      activeNames: [],

      repairList: '',
      repairContent: '',
      showPicker: false,
      startTime: '',
      endTime: '',

      repairUserIds: [],
      // repairPeople: '',
      // currentSelectPeople: '',
      // currentSelectPeopleId: '',
      //  searchList: [],

      timeStatus: 0,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
      repairFileList: [],
      token: '',

      showAcceptance: false,
      approvalUserIds: [],
      // acceptancePeople: '',
      // acceptanceSelectPeople: '',
      // acceptanceSelectPeopleId: '',
      // searchListAcceptance: [],

      columns: [],
    }
  },
  methods: {
    /**
     * 打开选择维修人员
     */
    // handleSelectRepairPerson() {
    //   this.repairPeople = ''
    //   this.currentSelectPeople = ''
    //   this.currentSelectPeopleId = ''
    //   this.showRepair = true
    // },
    /**
     * 打开选择验收人员
     */
    // handleSelectAcceptancePerson() {
    //   this.acceptancePeople = ''
    //   this.acceptanceSelectPeople = ''
    //   this.acceptanceSelectPeopleId = ''
    //   this.showAcceptance = true
    // },

    /**
     * 选择列表中的维修人员
     */
    // handleSelectRepairList(item) {
    //   this.repairPeople = item.realname
    //   this.currentSelectPeople = item.realname
    //   this.currentSelectPeopleId = item.id
    //   this.searchList = []
    // },
    /**
     * 选择列表中的维修人员
     */
    // handleSelectAcceptanceList(item) {
    //   this.acceptancePeople = item.realname
    //   this.acceptanceSelectPeople = item.realname
    //   this.acceptanceSelectPeopleId = item.id
    //   this.searchListAcceptance = []
    // },

    get_loadDeptWithUsersTrees() {
      loadDeptWithUsersTrees().then((res) => {
        let data = res.details.content
        let result = this.recurrence(data, 'children', 'userChildren')
        setTimeout(() => {
          this.columns = result
          var mobileSelect1 = new PickerExtend({
            trigger: '#trigger',
            title: '维修人员',
            triggerDisplayData: false,
            wheels: [
              {
                data: this.columns,
              },
            ],
            keyMap: {
              id: 'id',
              value: 'label',
              childs: 'children',
            },
            callback: (indexArr, data) => {
              let dataList = data[data.length - 1]
              var result = this.repairUserIds.every((item) => {
                return item.id != dataList.id
              })
              let list = this.findData(dataList.id, this.columns)
              console.log(list)
              if (!result) {
                Toast('请勿重复添加人员')
                return
              }
              if (list.hasOwnProperty('userChildren')) {
                Toast('请添加部门下对应人员')
                return
              }
              this.repairUserIds.push({
                name: dataList.label,
                id: dataList.id,
              })
            },
          })
          var mobileSelect2 = new PickerExtend({
            trigger: '#triggerAcceptance',
            title: '维修人员',
            triggerDisplayData: false,
            wheels: [
              {
                data: this.columns,
              },
            ],
            keyMap: {
              id: 'id',
              value: 'label',
              childs: 'children',
            },
            callback: (indexArr, data) => {
              let dataList = data[data.length - 1]
              var result = this.approvalUserIds.every((item) => {
                return item.id != dataList.id
              })
              let list = this.findData(dataList.id, this.columns)
              console.log(list)
              if (!result) {
                Toast('请勿重复添加人员')
                return
              }
              if (list.hasOwnProperty('userChildren')) {
                Toast('请添加部门下对应人员')
                return
              }
              this.approvalUserIds.push({
                name: dataList.label,
                id: dataList.id,
              })
            },
          })
        }, 100)
      })
    },
    findData(id, data) {
      let result = null
      function recurrenceData(id, data) {
        data.map((item) => {
          if (item.id == id) {
            result = item
          }
          if (item.children.length != '0' && !result) {
            recurrenceData(id, item.children)
          }
        })
        return result
      }
      return recurrenceData(id, data)
    },
    //递归处理数据
    recurrence(data, sameChildren, sameLevelChildren) {
      function recurrenceData(data, sameChildren, sameLevelChildren) {
        let result = data.map((item) => {
          if (item[sameChildren]) {
            recurrenceData(item[sameChildren], sameChildren, sameLevelChildren)
          }
          item[sameLevelChildren].map((items) => {
            items.children = []
          })
          item[sameChildren] = item[sameChildren].concat(
            item[sameLevelChildren]
          )
          return item
        })
        return result
      }
      return recurrenceData(data, sameChildren, sameLevelChildren)
    },

    /**
     * 确定添加验收人员
     */
    // confirmAcceptanceAdd() {
    //   this.searchListAcceptance = []
    //   if (!this.acceptancePeople) {
    //     this.showAcceptance = true
    //     Toast('员工工号不能为空！')
    //     return
    //   }
    //   if (!this.acceptanceSelectPeople) {
    //     this.showAcceptance = true
    //     Toast('没有搜索到该员工！')
    //     return
    //   }

    //   if (this.acceptanceSelectPeople && this.acceptanceSelectPeopleId) {
    //     let flag = true
    //     this.searchListAcceptance.forEach((item) => {
    //       if (item.id === this.acceptanceSelectPeopleId) {
    //         flag = false
    //         Toast('验收人员中已经存在！')
    //         return
    //       }
    //     })
    //     if (flag) {
    //       this.searchListAcceptance.push({
    //         id: this.acceptanceSelectPeopleId,
    //         name: this.acceptanceSelectPeople,
    //       })
    //     }
    //   }
    // },
    /**
     * 取消添加维修人员
     */
    // cancelAcceptanceAdd() {
    //   this.showAcceptance = false
    // },
    /**
     * 删除验收人员
     */
    deleteAcceptanceUser(index) {
      if (this.approvalUserIds[index].id === this.detailInfo.userId) {
        Toast('不能删除自己！')
        return
      }
      this.approvalUserIds.splice(index, 1)
    },
    /**
     * 确定添加维修人员
     */
    // confirmRepairAdd() {
    //   this.searchList = []
    //   if (!this.repairPeople) {
    //     this.showRepair = true
    //     Toast('员工工号不能为空！')
    //     return
    //   }
    //   if (!this.currentSelectPeople) {
    //     this.showRepair = true
    //     Toast('没有搜索到该员工！')
    //     return
    //   }

    //   if (this.currentSelectPeople && this.currentSelectPeopleId) {
    //     let flag = true
    //     this.repairUserIds.forEach((item) => {
    //       if (item.id === this.currentSelectPeopleId) {
    //         flag = false
    //         Toast('维修人员中已经存在！')
    //         return
    //       }
    //     })
    //     if (flag) {
    //       this.repairUserIds.push({
    //         id: this.currentSelectPeopleId,
    //         name: this.currentSelectPeople,
    //       })
    //     }
    //   }
    // },
    /**
     * 取消添加维修人员
     */
    // cancelRepairAdd() {
    //   this.showRepair = false
    // },
    /**
     * 删除维修人员
     */
    deleteRepairUser(index) {
      if (this.repairUserIds[index].id === this.userInfo.id) {
        Toast('不能删除自己！')
        return
      }
      this.repairUserIds.splice(index, 1)
    },
    onConfirm(value, index) {
      console.log(value)
      const time = moment(value).format('YYYY-MM-DD HH:mm:ss')
      if (this.timeStatus === 0) {
        if (this.endTime) {
          if (moment(this.endTime) > moment(time)) {
            this.startTime = time
          } else {
            Toast('开始时间不能大于结束时间！')
          }
        } else {
          this.startTime = time
        }
      } else {
        if (this.startTime) {
          if (moment(this.startTime) < moment(time)) {
            this.endTime = time
          } else {
            Toast('开始时间不能大于结束时间！')
          }
        } else {
          this.endTime = time
        }
      }
      this.showPicker = false
    },
    handleSelectStartTime() {
      this.timeStatus = 0
      this.showPicker = true
    },
    handleSelectEndTime() {
      this.timeStatus = 1
      this.showPicker = true
    },
    handleCloseClick() {
      this.show = false
    },
    /**
     * 获取七牛云token
     */
    getQiNiuToken() {
      getQiNiuToken().then((res) => {
        this.token = res.qiniuToken
      })
    },
    /**
     * 上传图片
     */
    uploadImg(file) {
      let content = file.file
      let data = new FormData()
      data.append('file', content)
      data.append('token', this.token)
      axios.post(QiNiuUrl, data).then((res) => {
        const data = res.data
        this.repairFileList.pop()
        this.repairFileList.push({
          url: imgUrl + data.hash,
          isImage: true,
          hash: data.hash,
        })
      })
    },
    /**
     * 根据工号获取员工接口
     */
    // getEmployeeUser() {
    //   this.currentSelectPeople = ''
    //   this.currentSelectPeopleId = ''
    //   getEmployeeUser(this.repairPeople).then((res) => {
    //     if (res.retCode === 1) {
    //       this.searchList = res.details.content
    //     }
    //   })
    // },
    /**
     * 根据工号获取员工接口
     */
    // getEmployeeAcceptanceUser() {
    //   this.acceptanceSelectPeople = ''
    //   this.acceptanceSelectPeopleId = ''
    //   getEmployeeUser(this.acceptancePeople).then((res) => {
    //     if (res.retCode === 1) {
    //       this.searchListAcceptance = res.details.content
    //     }
    //   })
    // },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../style/mixin';
.info-fault-wrapper {
  background-color: white;
  .van-repair-box {
    /deep/.van-dialog {
      border-radius: 0.2rem;
      .van-dialog__header {
        padding-top: 1rem;
      }
      .van-dialog__content {
        margin: 0.5rem 0 1rem 0.5rem;
        .van-field {
          width: 90%;
          border: 1px solid #e4dfdf;
          margin: 0 auto;
          border-radius: 0.2rem;
          padding: 0.3rem 0.5rem;
        }
      }
      .van-dialog__footer--buttons {
        .van-button {
          height: 2.5rem;
          line-height: 2.5rem;
          span {
            color: #4a8cff;
          }
        }
      }
    }
    .search-wrapper {
      .search-result-wrapper {
        width: 90%;
        margin: 0.2rem auto;
        border: 1px solid #e4dfdf;
        border-radius: 0.2rem;
        box-sizing: border-box;
        padding: 0.3rem 0;
        ul {
          li {
            box-sizing: border-box;
            padding-left: 0.5rem;
            font-size: 0.8rem;
            line-height: 2rem;
          }
        }
      }
    }
  }
  /deep/.list-item-wrapper {
    @include wh(100%, 2.5rem);
    .item-wrapper {
      .left {
        width: 50%;
      }
    }
  }
  i {
    color: red;
  }
  .select-box {
    span {
      font-size: 0.7rem;
      color: #999999;
    }
    i {
      color: #999999;
      font-size: 0.8rem;
    }
  }
  .fault-reason-box {
    box-sizing: border-box;
    padding: 0rem 1rem;
    /deep/.van-field {
      padding: 0.5rem 1rem;
      background-color: #f1f1f1;
    }
  }
  .upload-img-box {
    width: 100%;
    box-sizing: border-box;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    .title {
      font-size: 0.8rem;
      color: black;
      line-height: 1.5rem;
      margin-bottom: 0.5rem;
    }
    /deep/.van-tag {
      padding: 0.3rem;
      margin: 0.2rem;
    }
    /deep/.van-uploader__preview {
      margin: 0 0.25rem 0.25rem 0;
      .van-image {
        @include wh(3rem, 3rem);
        // border-radius: 3rem !important;
        overflow: hidden;
      }
      .van-uploader__preview-delete {
        color: #ce2324;
        @include wh(1rem, 1rem);
      }
    }
    /deep/.van-uploader__upload {
      @include wh(3rem, 3rem);
      border-radius: 3rem !important;
      overflow: hidden;
      background-color: #f2f2f2;
    }
    span.item-img-box {
      @include wh(2.8rem, 2.8rem);
      border-radius: 3rem !important;
      overflow: hidden;
      background-color: #f2f2f2;
      display: inline-block;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }
    span.add-btn {
      @include wh(2.8rem, 2.8rem);
      border-radius: 3rem !important;
      overflow: hidden;
      background-color: #f2f2f2;
      display: inline-block;
      text-align: center;
      line-height: 3.5rem;
      margin-bottom: 0.5rem;
      i {
        color: #969799;
        font-size: 24px;
      }
    }
  }
  .van-qrcode-box {
    /deep/.van-popup {
      background: rgba(0, 0, 0, 0);
    }
    .qrcode-img-box {
      @include wh(13rem, 15rem);
      border-radius: 0.5rem;
      box-sizing: border-box;
      position: relative;
      padding-top: 2rem;
      .img-box {
        @include wh(100%, 100%);
        background-color: white;
        box-sizing: border-box;
        padding: 1.5rem;
        border-radius: 0.5rem;
      }
      i {
        position: absolute;
        right: 0.1rem;
        top: 0rem;
        color: white;
        font-size: 1rem;
      }
      p {
        position: absolute;
        bottom: 0.3rem;
        width: 100%;
        text-align: center;
        font-size: 0.7rem;
        color: #333333;
      }
    }
  }
}
</style>
