<template>
  <div class="repair-info-wrapper">
    <div class="title-box">
      <Title title="设备信息" />
    </div>
    <div class="header-box">
      <InfoHeader :detailInfo="detailInfo" />
    </div>
    <div class="title-box" v-show="query && query.fault == 'true'">
      <Title title="故障信息" />
    </div>
    <div class="fault-box" v-show="query && query.fault == 'true'">
      <InfoFault ref="infoFault" :detailInfo="detailInfo" />
    </div>
    <div class="title-box" v-show="query && query.maintain == 'true'">
      <Title title="维修信息" />
    </div>
    <div class="fault-box" v-show="query && query.maintain == 'true'">
      <InfoRepair ref="infoRepair" :detailInfo="detailInfo" />
    </div>
    <div class="btn-box">
      <Button @handleButtonClick="handleSubmitClick">提交</Button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Title from '@/components/Title'
import InfoHeader from './components/InfoHeader'
import InfoFault from './components/InfoFault'
import InfoRepair from './components/InfoRepair'
import Button from '@/components/Button'
import { Toast } from 'vant'
import {
  getPreInitiate,
  getDeviceRepairDetail,
  postInitiate,
  finishOrder,
} from '@/api/deviceRepair'

Vue.use(Toast)
export default {
  name: 'RepairInfo',
  components: {
    Title,
    InfoHeader,
    InfoFault,
    InfoRepair,
    Button,
  },
  data() {
    return {
      detailInfo: {},
      query: null,
    }
  },
  created() {
    this.$nextTick(() => {
      this.query = this.$route.query
      if (this.query.faultCanEidt == 'true') {
        this.getPreInitiate()
      } else {
        this.getDeviceRepairDetail()
      }
    })
  },
  mounted() {},
  methods: {
    /**
     * 获取设备维修设备信息
     */
    getPreInitiate() {
      const deviceNo = this.$route.query.deviceNo
      getPreInitiate({
        deviceNo,
      })
        .then((res) => {
          if (res.retCode === 1) {
            this.detailInfo = res.details
          } else {
            Toast(res.retMsg)
          }
        })
        .catch((err) => {})
    },
    /**
     * 获取设备维修设备信息
     */
    getDeviceRepairDetail() {
      const deviceNo = this.$route.query.deviceNo
      getDeviceRepairDetail(deviceNo).then((res) => {
        if (res.retCode === 1) {
          this.detailInfo = res.details
          console.log(res.details)
        }
      })
    },
    /**
     * 提交
     */
    handleSubmitClick() {
      let params = {}
      let {
        faultLevel,
        faultLevelArr,
        faultReason,
        fileList,
      } = this.$refs.infoFault.$data
      let faultPhoto = ''

      if (!faultLevel && this.query.faultCanEidt == 'true') {
        Toast('请选择故障等级！')
        return
      }
      faultLevelArr.forEach((item) => {
        if (faultLevel === item.label) {
          faultLevel = item.value
        }
      })
      if (!faultReason && this.query.faultCanEidt == 'true') {
        Toast('请输入故障原因！')
        return
      }
      if (fileList.length) {
        fileList.forEach((item) => {
          if (faultPhoto) {
            faultPhoto = faultPhoto + ',' + item.hash
          } else {
            faultPhoto = faultPhoto + item.hash
          }
        })
      }

      let {
        startTime,
        endTime,
        repairContent,
        repairFileList,
        repairUserIds,
        approvalUserIds,
      } = this.$refs.infoRepair.$data
      let repairPhoto = ''

      if (!repairContent && this.query.maintain == 'true') {
        Toast('请输入维修内容！')
        return
      }

      if (!startTime && this.query.maintain == 'true') {
        Toast('请选择开始时间！')
        return
      }

      if (!endTime && this.query.maintain == 'true') {
        Toast('请选择结束时间！')
        return
      }
      if (repairFileList.length) {
        repairFileList.forEach((item) => {
          if (repairPhoto) {
            repairPhoto = repairPhoto + ',' + item.hash
          } else {
            repairPhoto = repairPhoto + item.hash
          }
        })
      }
      let arrRepair = []
      let arrAcceptance = []
      repairUserIds.forEach((item) => {
        arrRepair.push(item.id)
      })
      approvalUserIds.forEach((item) => {
        arrAcceptance.push(item.id)
      })

      if (this.query.faultCanEidt == 'true') {
        if (this.query.maintain == 'false') {
          params = {
            deviceId: this.detailInfo.deviceId,
            useDeptId: this.detailInfo.useDeptId,
            faultLevel,
            faultReason,
            faultPhoto,
            processType: '1',
          }
        } else {
          params = {
            deviceId: this.detailInfo.deviceId,
            useDeptId: this.detailInfo.useDeptId,
            faultLevel,
            faultReason,
            faultPhoto,
            repairContent,
            repairPhoto,
            repairStartTime: startTime,
            repairEndTime: endTime,
            repairUserIds: arrRepair.join(','),
            approvalUserIds: arrAcceptance.join(','),
            processType: '2',
          }
        }

        postInitiate(params).then((res) => {
          if (res.retCode === 1) {
            this.$router.push({
              path: '/deviceRepair/suc',
              query: {
                id: this.detailInfo.deviceId,
              },
            })
          } else {
            Toast(res.retMsg)
          }
        })
      } else {
        params = {
          repairContent,
          repairPhoto,
          repairStartTime: startTime,
          repairEndTime: endTime,
          realRepairUserIds: arrRepair.join(','),
          approvalUserIds: arrAcceptance.join(','),
        }
        finishOrder(this.detailInfo.id, params).then((res) => {
          if (res.retCode === 1) {
            this.$router.push({
              path: '/deviceRepair/suc',
              query: {
                id: this.detailInfo.id,
              },
            })
          } else {
            Toast(res.retMsg)
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../style/mixin';
.repair-info-wrapper {
  min-height: 100%;
  box-sizing: border-box;
  background-color: #f1f1f1;
  padding-bottom: 3rem;
  position: relative;
  .title-box {
    box-sizing: border-box;
    padding: 0 1rem;
  }
  .btn-box {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
  }
}
</style>
