<template>
  <div class="info-header-wrapper">
    <div class="left">
      <p>设备名称： {{detailInfo.deviceName}}</p>
      <p>设备编号： {{detailInfo.deviceNo}}</p>
      <p>使用部门： {{detailInfo.deptName}}</p>
    </div>
    <div class="right">
      <div class="icon">
        <img :src="imgUrl + detailInfo.devicePhotoUrl" alt="">
      </div>
    </div>
  </div>
</template>

<script>
  import { imgUrl } from '@/config/env'
  export default {
    name: "InfoHeader",
    props: {
      detailInfo: {
        type: Object,
        default() {
          return {}
        }
      }
    },
    data () {
      return {
        imgUrl
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../style/mixin";
  .info-header-wrapper{
    @include wh(100%, 8rem);
    background:linear-gradient(90deg,rgba(79,147,255,1) 0%,rgba(58,187,253,1) 100%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 2rem;
    .left{
      p{
        font-size: 0.8rem;
        color: white;
        line-height: 1.5rem;
      }
    }
    .right{
      .icon{
        @include wh(5rem, 5rem);
        background-color: white;
        img{
          @include wh(5rem, 5rem);
        }
      }
    }
  }
</style>
